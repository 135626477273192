import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SecurityPage from 'pages/SecurityPage';

import { ContentArea, Container, MenuButton, SideNav } from 'security-app/components/Layout/index';
import { StyledLink } from 'security-app/components/common';
import useLicenseCheck from 'license/useLicenseCheck';
import { securityRoutes, anomalyDetectionRoutes } from 'security-app/routing/routes';
import withIsPermitted from 'forwarder/util/withIsPermitted';
import StringUtils from 'util/StringUtils';
import Spinner from 'components/common/Spinner';
import { DocumentTitle, Icon } from 'components/common';
import PageContentLayout from 'components/layout/PageContentLayout';
import useSearchVersionCheck from 'hooks/useSearchVersionCheck';
import { isPermitted } from 'util/PermissionsMixin';
import useCurrentUser from 'hooks/useCurrentUser';

type Props = {
  children: React.ReactNode,
};

const REQUIRED_PERMISSIONS = ['graylog_security:read'];

const SecurityLayout = ({ children }: Props) => {
  const [showSideBar, setShowSideBar] = useState(true);
  const [title, setTitle] = useState('security');
  const { security: { isValid: isValidSecurityLicense, isLoading: isLoadingSecurityLicense } } = useLicenseCheck();
  const location = useLocation();
  const { isLoading: isLoadingSearchVersion } = useSearchVersionCheck('opensearch');
  const currentUser = useCurrentUser();
  const anomalyDetectionAccess: boolean = isPermitted(currentUser.permissions, ['anomaly_configuration:read']);

  useEffect(() => {
    const searchPattern = /\/security\//;
    const newLocation: string = location.pathname.replace(searchPattern, '');

    if (newLocation === '/security') {
      setTitle('Security');
    } else {
      setTitle(StringUtils.toTitleCase(newLocation, '-'));
    }
  }, [location]);

  if (isLoadingSecurityLicense || isLoadingSearchVersion) {
    return (
      <Spinner />
    );
  }

  if (!isValidSecurityLicense) {
    return (
      <PageContentLayout>
        <SecurityPage />
      </PageContentLayout>
    );
  }

  // TODO: Add PageContentLayout, you can override the styles for it.
  return (
    <DocumentTitle title={title}>
      <Container>
        <SideNav isOpen={showSideBar}>
          <ul>
            {securityRoutes.map((route) => (
              <li key={route.id}>
                <StyledLink exact to={route.path}>
                  <Icon name={route.iconName} />
                  {route.title}
                </StyledLink>
              </li>
            ))}
            {anomalyDetectionAccess
                && anomalyDetectionRoutes.map((route) => (
                  <li key={route.id}>
                    <StyledLink to={route.path}>
                      <Icon name={route.iconName} />
                      {route.title}
                    </StyledLink>
                  </li>
                ))}
          </ul>

          <MenuButton sideNavIsOpen={showSideBar} onClick={() => setShowSideBar(!showSideBar)}>
            <Icon name={showSideBar ? 'arrow-left' : 'th'} />
          </MenuButton>
        </SideNav>
        <ContentArea sideNavIsOpen={showSideBar}>
          {children}
        </ContentArea>
      </Container>
    </DocumentTitle>
  );
};

export default withIsPermitted(SecurityLayout, REQUIRED_PERMISSIONS);
